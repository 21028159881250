
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { emptyToLine } from "@/utils/common";
import StandardFlag from "@/components/scope/standard-flag.vue";
import { Pageniation, TableRowsSelect, Table } from "@/utils/decorator";
import TableEnableFlag from "@/components/scope/table-enable-flag.vue";
import { splitThousands } from "@/utils/common";
const management = namespace("management");
const base = namespace("base");
const qualityAssurance = namespace("qualityAssurance");
import SwitchStatus from "@/components/scope/switch-status.vue";
import t from "@common/src/i18n/t";
const service = namespace("service-item");
const app = namespace("app");
@Component({
  components: { TableEnableFlag, StandardFlag, SwitchStatus }
})
@Pageniation("loadListData")
@Table("loadListData")
@TableRowsSelect({
  warningMsg: t("setting.choose-payment-method"),
  updateStatusFuncName: "confirmUpdateStatus",
  tableRefKey: "table"
})
export default class Service extends Vue {
  @app.Mutation btnStartLoading;
  @app.Mutation btnStopLoading;

  @service.Action getServiceList;
  @qualityAssurance.Action getQualityAssuranceTemplateList;
  @qualityAssurance.Action stateChange;
  @qualityAssurance.Action assign;
  @qualityAssurance.Action cancelAssign;
  @qualityAssurance.Action getServiceStoreList;
  @qualityAssurance.Action matchServicesAndStores;

  selectedRows = []
  selectedServices =[]

  selectSearchFrom = {
    state: null
  };
  serviceSearchList = [];

  selectedStores=[];

  searchStoreList = [];
  keyword: string = "";
  serviceList: any[] = [];
  emptyToLine = emptyToLine;
  splitThousands = splitThousands;

  handleSearch() {
    this.mix_pageNum = 1;
    this.loadListData();
  }

  created() {
    this.loadListData();
  }

  /**
   * 移除标签
   * @param tag
   */
  removeTag(tag){
    const newSelectedRows = this.selectedRows.filter(item => item.qaTemplateCode !== tag.qaTemplateCode)
    this.selectedRows = newSelectedRows;
  }

  /**
   * 加载列表数据
   */
  @Pageniation.LoadDataMethod()
  loadListData() {
    return this.getQualityAssuranceTemplateList({
      orderItems: this.mix_sortParams,
      pageSize: this.mix_pageSize,
      pageNum: this.mix_pageNum,
      templateName: this.keyword,
      ...this.selectSearchFrom,
    }).then(data => {
      this.serviceList = data.data.list;
      return data;
    });
  }

  handleEdit(row) {
    this.$router.push(`/quality/assurance/edit/${row.qaTemplateCode}`);
  }

  confirmUpdateStatus(status, selectedRow?) {
    return this.stateChange({
      state: status == 1,
      qaTemplateCode: selectedRow.qaTemplateCode
    })
      .then(data => {
        this.$message.success(
          status
            ? (this.$t("setting.enable-successful") as string)
            : (this.$t("setting.disable-success") as string)
        );
        this.loadListData();
      })
      .catch(e => {
        if (e.code === "speedbiz.system.pay-method-least-one-valid") {
          this.$message.error(t("setting.valid-method-payment"));
        }
      });
  }

  multipleSelection = {
    size: 0
  };
  batchOpVisible: boolean = true;
  batchOpAssignType: number = 0; //1-分配 2取消分配

  handlerBatchOpOpen(){
    this.batchOpVisible = true;
    (this.$refs['serviceItemTable'] as any).clearSelection();
  }
  handlerBatchOpClose(){
    this.batchOpVisible = false;
    (this.$refs['serviceItemTable'] as any).clearSelection();
  }

  handleBathAssign(type){
    const currSelectedRows = (this.$refs['serviceItemTable'] as any).selection;
    if (currSelectedRows.length < 1) {
      this.$message.error("请选择模版");
      return;
    }
    this.selectedRows = currSelectedRows;
    this.batchOpAssignType = type;
    this.assignDialogVisible=true;
    if(type == 1){
      this.selectedStores = [];
      this.selectedServices = [];
      this.serviceSearchList = [];
      this.searchStoreList = [];

      this.getServiceList({
        pageSize: 999,
        pageNum: 1,
      }).then(data => {
        let list = data.data.list || [];
        list.forEach(item => {
          this.serviceSearchList.push({
            key: item.serviceCode,
            label: item.serviceName,
            disabled: false
          })
        })
      });
    }else{
      this.selectedStores = [];
      this.selectedServices = [];
      this.serviceSearchList = [];
      this.searchStoreList = [];

      let qaTemplateCodes = this.selectedRows.map(item => {
        return item.qaTemplateCode
      });
      this.matchServicesAndStores({qaTemplateCodes: qaTemplateCodes }).then(data => {
        data.data.services.forEach(item => {
          this.serviceSearchList.push({
            key: item.serviceCode,
            label: item.serviceName,
            disabled: false
          })
          // this.selectedServices.push(item.serviceCode)
        })

        data.data.stores.forEach(item=>{
          this.searchStoreList.push({
            key: item.storeCode,
            label: item.storeName,
            disabled: false
          })
          // this.selectedStores.push(item.storeCode)
        })
      })

    }
  }


  handleSelectionChange(val) {
    this.multipleSelection.size = val.length;
  }

  /**
   * 分配/取消分配
   */
  handleSaveBathAssign(){
    if (this.selectedRows.length < 1) {
      this.$message.error("请选择模版");
      return;
    }

    if (this.selectedServices.length < 1) {
      this.$message.error("请选择服务项");
      return;
    }

    if(this.selectedStores.length < 1){
      this.$message.error("请选择服务项");
      return;
    }

    const qaTemplateCodes = [];
    this.selectedRows.forEach(item => {
      qaTemplateCodes.push(item.qaTemplateCode);
    })

    if (this.batchOpAssignType == 1) {
      this.assign({qaTemplateCodes: qaTemplateCodes, serviceCodes:this.selectedServices,storeCodes: this.selectedStores}).then(data => {
          if(data.code === "0"){
              this.$message.success(this.$t("base.success") as any);
              this.assignDialogVisible = false;
              return ;
          }
      })
    } else if (this.batchOpAssignType == 2) {
      this.cancelAssign({qaTemplateCodes: qaTemplateCodes, serviceCodes:this.selectedServices,storeCodes: this.selectedStores}).then(data => {
        if(data.code === "0"){
            this.$message.success(this.$t("base.success") as any);
            this.assignDialogVisible = false;
            return ;
        }
      })
    }else{
      this.$message.error("请选择操作类型");
    }
  }

  filterMethod(query, item) {
    return item.label.indexOf(query) > -1;
  }

  filterStoreMethod(query,item){
    return item.label.indexOf(query) > -1;
  }
  /**
   * 服务项穿梭框变动事件处理
   * @param direction
   */
  selectedServiceChange(direction){
    if (this.batchOpAssignType == 1) {
      this.searchStoreList = []
      this.getServiceStoreList({ serviceCodes: this.selectedServices}).then(data => {
        let list = data.data || [];
        list.forEach(item => {
          this.searchStoreList.push({
            key: item.storeCode,
            label: item.storeName,
            disabled: false
          })
        })
        this.selectedStores = this.selectedStores.filter(item => list.concat(item))
      })
    }
  }
  assignDialogVisible=false;
  // 启用状态，服务分类选择查询
  searchListFn(e) {
    this.mix_pageSize = 10;
    this.mix_pageNum = 1;
    this.loadListData();
  }
}
