
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { emptyToLine, splitThousands } from "@/utils/common";
import BtnCheckGroup from "@/components/scope/btn-check-group.vue";
import GeneralVehicleSelect from "@/components/scope/general-vehicle-select.vue";
import dayjs from "dayjs";
const qualityAssurance = namespace("qualityAssurance");
const app = namespace("app");

@Component({
  components: { BtnCheckGroup, GeneralVehicleSelect }
})
export default class ServiceItemEdit extends Vue {
  @app.Mutation setPBPX;
  @app.State btnLoading;
  @app.Mutation btnStopLoading;
  @qualityAssurance.Action editQualityAssuranceTemplate
  @qualityAssurance.Action getQualityAssuranceTemplateDetail;
  dayjs = dayjs;
  splitThousands = splitThousands;

  orderFormData: any = {
    qaTemplateCode: "",
    templateName: "",
    desc:"",
    items:[],
  };

  get breadData() {
    return [
      { name: "质保单", path: "/quality/assurance/add" },
      {
        name: this.isAdd ? "新增模版" : "编辑模版"
      }
    ];
  }

  get isAdd() {
    return this.$route.name === "qualityAssuranceAdd";

  }
  orderFormDataRules = {
    templateName: [{ required: true, message: "请输入模版名", trigger: "blur" }],
  };

  emptyToLine = emptyToLine;

  definitionData: any = {
    attrKey: "",
    attrValue: "",
  };

  get orderId() {
    return this.$route.params.id;
  }
  created() {
    this.setPBPX(52);
    this.init();
  }

  handleAdd() {
    this.orderFormData.items.push(Object.assign({}, this.definitionData));
  }

  async init() {
    this.orderFormData.qaTemplateCode = this.$route.params.id
    if (!this.isAdd) {
      this.getQualityAssuranceTemplateDetail(this.orderFormData.qaTemplateCode).then(data => {
        if(data.code === "0"){
          Object.assign(this.orderFormData, data.data);
        }
      })
    }
  }

  submitForm(formName) {
    (this.$refs.serviceForm as any).validate(valid => {
      if (valid) {
        this.editQualityAssuranceTemplate(this.orderFormData)
          .then(data => {
            this.$message.success(this.$t("base.success") as any);
            this.$router.go(-1);
          })
          .finally(item => {
            this.btnStopLoading();
          });
      } else {
        console.log("error submit!!");
      }
    });
  }

  deleteItem(key, index) {
    this.orderFormData[key].splice(index, 1);
  }
}
