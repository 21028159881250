import Vue from "vue";
import VueRouter from "vue-router";
import Router from "vue-router";
import commonRouters from "@common/src/router/index";
import t from "@common/src/i18n/t";

const originalPush = Router.prototype.push;
(Router.prototype as any).push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};
Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/user/login.vue"),
    meta: {
      title: t("login.login")
    }
  },
  {
    path: "/speedbiz-ui-test",
    name: "speedbizUiTest",
    component: () => import("@/views/speedbiz-ui/index.vue"),
    meta: {
      title: t("v210901.speedbiz-ui-test")
    }
  },
  {
    path: "/change-pwd",
    name: "changePwd",
    component: () => import("../views/user/change-pwd.vue"),
    meta: {
      title: t("login.change-pwd")
    }
  },
  {
    path: "/forget-pwd",
    name: "forgetPwd",
    component: () => import("../views/user/forget-pwd.vue"),
    meta: {
      title: t("v210901.find-pwd")
    }
  },
  {
    path: "/change-success",
    name: "changeSuccess",
    component: () => import("../views/user/change-success.vue"),
    meta: {
      title: t("v210901.change-success")
    }
  },
  {
    path: "/404",
    name: "notFound",
    component: () => import("../views/other/404.vue"),
    meta: {
      title: t("v210901.not-found-404")
    }
  },
  {
    // 首页
    path: "/",
    name: "layout",
    component: () => import("../views/layout/index.vue"),
    // eslint-disable-next-line no-sparse-arrays
    children: [
      // 卡模板index
      {
        path: "/marketing/card-template",
        name: "marketingCardTemplate",
        component: () => import("../views/marketing/card-template-list.vue"),
        meta: {
          title: t("marketing.card-template"),
          menuKey: "marketing"
        }
      },
      // 卡模板-新建index
      {
        path: "/marketing/card-template-add",
        name: "marketingCardTemplateAdd",
        component: () => import("../views/marketing/card-template-edit.vue"),
        meta: {
          title: t("v210901.new-card-template"),
          menuKey: "marketing"
        }
      },
      // 卡模板-详情index
      {
        path: "/marketing/card-template-view/:id",
        name: "marketingCardTemplateView",
        component: () => import("../views/marketing/card-template-view.vue"),
        meta: {
          title: t("v210901.card-template-detail"),
          menuKey: "marketing"
        }
      },
      // 卡管理index
      {
        path: "/marketing/card",
        name: "marketingCard",
        component: () => import("../views/marketing/card-list.vue"),
        meta: {
          title: t("marketing.card-manage"),
          menuKey: "marketing"
        }
      },
      // 卡管理-开卡index
      {
        path: "/marketing/card-open",
        name: "cardOpen",
        component: () => import("../views/marketing/card-open.vue"),
        meta: {
          title: t("marketing.card-manage"),
          menuKey: "marketing"
        }
      },
      // 优惠券index
      {
        path: "/marketing/discount-coupon",
        name: "marketingDiscountCoupon",
        component: () => import("../views/marketing/coupon/discount-coupon.vue"),
        meta: {
          title: t("marketing.discount-coupon"),
          menuKey: "marketing"
        }
      },
      // 优惠券-新建index
      {
        path: "/marketing/created-coupon/add",
        name: "marketingCreatedCouponAdd",
        component: () => import("../views/marketing/coupon/created-coupon.vue"),
        meta: {
          title: t("v210801.new-coupon"),
          menuKey: "marketing"
        }
      },
      // 优惠券发放index
      {
        path: "/marketing/coupon-send",
        name: "marketingSendCoupon",
        component: () => import("../views/marketing/coupon/coupon-send.vue"),
        meta: {
          title: t("v210831.coupon-issuance"),
          menuKey: "marketing"
        }
      },
      {
        path: "/marketing/created-coupon/:code",
        name: "marketingCreatedCouponEdit",
        component: () => import("../views/marketing/coupon/created-coupon.vue"),
        meta: {
          title: t("v210801.new-coupon"),
          menuKey: "marketing"
        }
      },
      {
        path: "/marketing/look-coupon/:activityCode",
        name: "marketingLookCoupon",
        component: () => import("../views/marketing/coupon/look-coupon.vue"),
        meta: {
          title: t("v210831.view-coupons"),
          menuKey: "marketing"
        }
      },
      {
        path: "/marketing/send-coupon/:activityCode",
        name: "marketingSendCoupon",
        component: () => import("../views/marketing/coupon/send-coupon.vue"),
        meta: {
          title: t("v210831.issue-coupons"),
          menuKey: "marketing"
        }
      },
      {
        path: "/marketing/return-rule-set",
        name: "marketingReturnRuleSet",
        component: () => import("../views/marketing/return-rule-set.vue"),
        meta: {
          title: t("v210831.return-visit-rule-set"),
          menuKey: "marketing"
        }
      },
      {
        path: "/marketing/return-appraise",
        name: "marketingReturnAppraise",
        component: () => import("../views/marketing/return-appraise.vue"),
        meta: {
          title: t("v210831.return-visit-evaluation"),
          menuKey: "marketing"
        }
      },
      {
        path: "/marketing/return/:revisitCommentCode",
        name: "marketingReturn",
        component: () => import("../views/marketing/return.vue"),
        meta: {
          title: t("v210831.return-visit-evaluation"),
          menuKey: "marketing"
        }
      },
      {
        path: "/marketing/upkeep-alert-set",
        name: "marketingUpkeepAlertSet",
        component: () => import("../views/marketing/upkeep-alert-set.vue"),
        meta: {
          title: t("v210831.maintenance-reminder-settings"),
          menuKey: "marketing"
        }
      },
      {
        path: "/marketing/upkeep-alert",
        name: "marketingUpkeepAlert",
        component: () => import("../views/marketing/upkeep-alert.vue"),
        meta: {
          title: t("v210831.maintenance-reminder"),
          menuKey: "marketing"
        }
      },
      {
        path: "/marketing/value-card-add",
        name: "ValueCardAdd",
        component: () => import("../views/marketing/value-card-edit.vue"),
        meta: {
          title: t("v210831.new-stored-value-card-template"),
          menuKey: "marketing"
        }
      },
      {
        path: "/marketing/value-card-edit/:id",
        name: "ValueCardEdit",
        component: () => import("../views/marketing/value-card-edit.vue"),
        meta: {
          title: t("v210831.edit-stored-value-card-template"),
          menuKey: "marketing"
        }
      },
      {
        path: "/marketing/value-card-view/:id",
        name: "ValueCardView",
        component: () => import("../views/marketing/value-card-view.vue"),
        meta: {
          title: t("v210901.view-stored-value-card-template"),
          menuKey: "marketing"
        }
      },
      {
        path: "/marketing/card-template-edit/:id",
        name: "marketingCardTemplateEdit",
        component: () => import("../views/marketing/card-template-edit.vue"),
        meta: {
          title: t("marketing.edit-card-template"),
          menuKey: "marketing"
        }
      },
      {
        path: "/marketing/card-view/:id",
        name: "marketingCardView",
        component: () => import("../views/marketing/card-view.vue"),
        meta: {
          title: t("v210901.card-details"),
          menuKey: "marketing"
        }
      },
      {
        path: "/marketing/campaign/collect-customer",
        name: "collectCustomer",
        component: () => import("../views/marketing/campaign/collect-customer.vue"),
        meta: {
          title: t("v210801.customer-accumulation-activities"),
          menuKey: "marketing"
        }
      },
      {
        path: "/marketing/campaign/collect-customer-add",
        name: "collectCustomerAdd",
        component: () => import("../views/marketing/campaign/collect-customer-edit.vue"),
        meta: {
          title: t("v210801.new-customer-accumulation"),
          menuKey: "marketing"
        }
      },
      {
        path: "/marketing/campaign/collect-customer-edit/:id",
        name: "collectCustomerEdit",
        component: () => import("../views/marketing/campaign/collect-customer-edit.vue"),
        meta: {
          title: t("v210901.edit-customer-accumulation-activities"),
          menuKey: "marketing"
        }
      },
      {
        path: "/marketing/campaign/collect-customer-view/:id",
        name: "collectCustomerView",
        component: () => import("../views/marketing/campaign/collect-customer-view.vue"),
        meta: {
          title: t("v210901.details-of-customer-accumulation-activities"),
          menuKey: "marketing"
        }
      },
      {
        path: "/marketing/campaign/send-collect-customer/:id",
        name: "sendCollectCustomer",
        component: () => import("../views/marketing/campaign/send-collect-customer.vue"),
        meta: {
          title: t("v210901.send-customer-accumulation-activities"),
          menuKey: "marketing"
        }
      },
      {
        path: "/marketing/campaign/fission-campagin",
        name: "fissionCampagin",
        component: () => import("../views/marketing/campaign/fission-campagin.vue"),
        meta: {
          title: t("v210801.fission-activity"),
          menuKey: "marketing"
        }
      },
      {
        path: "/marketing/campaign/fission-send-collect/:id",
        name: "fissionSendCollect",
        component: () => import("../views/marketing/campaign/fission-send-collect.vue"),
        meta: {
          title: t("v210901.send-fission-activity"),
          menuKey: "marketing"
        }
      },
      {
        path: "/marketing/campaign/fission-campagin-add",
        name: "fissionCampaginAdd",
        component: () => import("../views/marketing/campaign/fission-campagin-edit.vue"),
        meta: {
          title: t("v210801.new-fission-activity"),
          menuKey: "marketing"
        }
      },
      {
        path: "/marketing/campaign/fission-campagin-view/:id",
        name: "fissionCampaginView",
        component: () => import("../views/marketing/campaign/fission-campagin-view.vue"),
        meta: {
          title: t("v210901.fission-activity-detail"),
          menuKey: "marketing"
        }
      },
      {
        path: "/marketing/campaign/fission-campagin-add/:id",
        name: "fissionCampaginEdit",
        component: () => import("../views/marketing/campaign/fission-campagin-edit.vue"),
        meta: {
          title: t("v210801.edit-fission-activity"),
          menuKey: "marketing"
        }
      },
      {
        path: "/marketing/campaign/fission-analysis/:id",
        name: "fissionAnalysis",
        component: () => import("../views/marketing/campaign/fission-analysis.vue"),
        meta: {
          title: t("v210901.fission-activity-data"),
          menuKey: "marketing"
        }
      },
      {
        path: "/marketing/campaign/lottery",
        name: "lottery",
        component: () => import("../views/marketing/campaign/lottery.vue"),
        meta: {
          title: t("v210801.lucky-draw"),
          menuKey: "marketing"
        }
      },
      {
        path: "/marketing/campaign/lottery-add",
        name: "lotteryAdd",
        component: () => import("../views/marketing/campaign/lottery-edit.vue"),
        meta: {
          title: t("v210831.new-lucky-draw"),
          menuKey: "marketing"
        }
      },

      {
        path: "/marketing/campaign/lottery-edit/:id",
        name: "lotteryEdit",
        component: () => import("../views/marketing/campaign/lottery-edit.vue"),
        meta: {
          title: t("v210831.edit-lucky-draw"),
          menuKey: "marketing"
        }
      },
      {
        path: "/marketing/campaign/lottery-view/:id",
        name: "lotteryView",
        component: () => import("../views/marketing/campaign/lottery-view.vue"),
        meta: {
          title: t("v210901.raffle-details"),
          menuKey: "marketing"
        }
      },
      {
        path: "/marketing/campaign/send-lottery-qualification/:id",
        name: "sendCollectCustomer",
        component: () => import("../views/marketing/campaign/send-lottery-qualification.vue"),
        meta: {
          title: t("v210831.send-lucky-draw"),
          menuKey: "marketing"
        }
      },
      {
        path: "/marketing/campaign/collect-analysis/:id",
        name: "lotteryCampaignAnalysis",
        component: () => import("../views/marketing/campaign/collect-analysis.vue"),
        meta: {
          title: t("v210901.customer-accumulation-activity-data"),
          menuKey: "marketing"
        }
      },
      {
        path: "/marketing/campaign/lottery-analysis/:id",
        name: "lotteryCampaignAnalysis",
        component: () => import("../views/marketing/campaign/lottery-analysis.vue"),
        meta: {
          title: t("v210901.lottery-activity-data"),
          menuKey: "marketing"
        }
      },
      {
        path: "/marketing/mini-program/setting",
        name: "miniProgramSetting",
        component: () => import("../views/marketing/mini-program/setting.vue"),
        meta: {
          title: t("v210901.applet-settings"),
          menuKey: "marketing"
        }
      },
      {
        path: "/marketing/message-recharge",
        name: "messageRecharge",
        component: () => import("../views/marketing/message-recharge.vue"),
        meta: {
          title: t("v210831.sms-recharge"),
          menuKey: "marketing"
        }
      },
      {
        path: "/marketing/recharge-record",
        name: "rechargeRecord",
        component: () => import("../views/marketing/recharge-record.vue"),
        meta: {
          title: t("v210831.recharge-record"),
          menuKey: "marketing"
        }
      },
      {
        path: "/marketing/sent-record",
        name: "sentRecord",
        component: () => import("../views/marketing/sent-record.vue"),
        meta: {
          title: t("v210831.send-record"),
          menuKey: "marketing"
        }
      },
      {
        path: "",
        redirect: "/management/tenant-list"
      },
      {
        path: "/management/tenant-list",
        name: "ManagementTenantList",
        component: () => import("../views/management/tenant-list.vue"),
        meta: {
          title: t("v210831.tenant-management"),
          menuKey: "store"
        }
      },
      {
        path: "/management/tenant-edit/:id",
        name: "ManagementTenantEdit",
        component: () => import("../views/management/tenant-edit.vue"),
        meta: {
          title: t("v210901.edit-lessee"),
          menuKey: "store"
        }
      },
      {
        path: "/management/store-list",
        name: "ManagementStoreList",
        component: () => import("../views/management/store-list.vue"),
        meta: {
          title: t("v210831.store-management"),
          menuKey: "store"
        }
      },
      {
        path: "/management/store-edit/:id",
        name: "ManagementStoreEdit",
        component: () => import("../views/management/store-edit.vue"),
        meta: {
          title: t("v210901.edit-store"),
          menuKey: "store"
        }
      },
      {
        path: "/management/store-view/:id",
        name: "ManagementStoreView",
        component: () => import("../views/management/store-view.vue"),
        meta: {
          title: t("v210831.store-details"),
          menuKey: "store"
        }
      },
      {
        path: "/management/apply-view/:id",
        name: "ManagementApplyView",
        component: () => import("../views/management/store-view.vue"),
        meta: {
          title: t("v210831.store-details"),
          menuKey: "store"
        }
      },
      {
        path: "/management/apply-list",
        name: "ManagementApplyEdit",
        component: () => import("../views/management/apply-list.vue"),
        meta: {
          title: t("v210901.apply-list"),
          menuKey: "store"
        }
      },
      {
        path: "/management/store-open",
        name: "ManagementStoreOpen",
        component: () => import("../views/management/store-open.vue"),
        meta: {
          title: t("v210901.store-opening"),
          menuKey: "store"
        }
      },
      {
        path: "/management/tmall/tmall-data-change",
        component: () => import("../views/management/tmall/tmall-data-change.vue"),
        meta: {
          title: t("v210831.tmall-data-change"),
          menuKey: "store"
        }
      },
      {
        path: "/management/tmall/change-diff/:id",
        component: () => import("../views/management/tmall/change-data-diff.vue"),
        meta: {
          title: t("v210901.tmall-data-change-detail"),
          menuKey: "store"
        }
      },
      {
        path: "/management/store-create-suc",
        name: "ManagementStoreOpen",
        component: () => import("../views/management/store-create-success.vue"),
        meta: {
          title: t("v210901.store-create-success"),
          menuKey: "store"
        }
      },
      {
        path: "/management/fission/fission-campagin",
        name: "fissionCampagin",
        component: () => import("../views/management/fission/fission-campagin.vue"),
        meta: {
          title: t("v210801.platform-fission-activity"),
          menuKey: "operation"
        }
      },
      {
        path: "/management/fission/fission-campagin-add",
        name: "fissionCampaginAdd",
        component: () => import("../views/management/fission/fission-campagin-edit.vue"),
        meta: {
          title: t("v210801.new-fission-activity"),
          menuKey: "operation"
        }
      },
      {
        path: "/management/fission/fission-campagin-add/:id",
        name: "fissionCampaginEdit",
        component: () => import("../views/management/fission/fission-campagin-edit.vue"),
        meta: {
          title: t("v210801.edit-fission-activity"),
          menuKey: "marketing"
        }
      },
      {
        path: "/management/fission/fission-campagin-view/:id",
        name: "fissionCampaginView",
        component: () => import("../views/management/fission/fission-campagin-view.vue"),
        meta: {
          title: t("v210901.fission-activity-detail"),
          menuKey: "marketing"
        }
      },
      {
        path: "/management/fission/fission-share-award",
        name: "fissionShareAward",
        component: () => import("../views/management/fission/fission-share-award.vue"),
        meta: {
          title: "裂变卡券奖励",
          menuKey: "operation"
        }
      },
      {
        path: "/management/fission/fission-analysis/:id",
        name: "fissionAnalysis",
        component: () => import("../views/management/fission/fission-analysis.vue"),
        meta: {
          title: t("v210901.fission-activity-data"),
          menuKey: "operation"
        }
      },
      {
        path: "/management/fission/fission-analysis-page/:id",
        name: "fissionAnalysisNew",
        component: () => import("../views/management/fission/fission-analysis-newpage.vue"),
        meta: {
          title: t("v210901.fission-activity-data"),
          menuKey: "management"
        }
      },
      {
        path: "/management/fission-bill/fission-bill",
        name: "fissionBill",
        component: () => import("../views/management/fission-bill/fission-bill.vue"),
        meta: {
          title: t("v210831.fission-marketing-bill"),
          menuKey: "operation"
        }
      },
      {
        path: "/management/fission-bill/bill-batch",
        name: "fissionBill",
        component: () => import("../views/management/fission-bill/bill-batch.vue"),
        meta: {
          title: t("v210831.batch-settlement"),
          menuKey: "operation"
        }
      },
      {
        path: "/management/fission-bill/bill-batch/:storeCode",
        name: "fissionBillStore",
        component: () => import("../views/management/fission-bill/bill-batch.vue"),
        meta: {
          title: t("v210831.batch-settlement"),
          menuKey: "operation"
        }
      },
      {
        path: "/management/parts-list",
        name: "ManagementPartsList",
        component: () => import("../views/management/parts-list.vue"),
        meta: {
          title: t("v210901.platform-parts"),
          menuKey: "operation"
        }
      },
      {
        path: "/management/parts-add",
        name: "ManagementPartsAdd",
        component: () => import("../views/management/parts-edit.vue"),
        meta: {
          title: t("setting.added-parts"),
          menuKey: "operation"
        }
      },
      {
        path: "/management/parts-edit/:id",
        name: "ManagementPartsEdit",
        component: () => import("../views/management/parts-edit.vue"),
        meta: {
          title: t("setting.editing-spare-parts"),
          menuKey: "operation"
        }
      },
      {
        path: "/management/parts-category",
        name: "ManagementPartsClass",
        component: () => import("../views/management/parts-category.vue"),
        meta: {
          title: t("parts.category"),
          menuKey: "operation"
        }
      },
      {
        path: "/management/card-template",
        name: "ManagementCardTemplate",
        component: () => import("../views/management/card-template-list.vue"),
        meta: {
          title: t("v210901.platform-card"),
          menuKey: "operation"
        }
      },
      {
        path: "/management/card-template-add",
        name: "ManagementCardTemplateAdd",
        component: () => import("../views/management/card-template-edit.vue"),
        meta: {
          title: t("marketing.add-card-template"),
          menuKey: "operation"
        }
      },
      {
        path: "/management/card-template-edit/:id",
        name: "ManagementCardTemplateEdit",
        component: () => import("../views/management/card-template-edit.vue"),
        meta: {
          title: t("marketing.edit-card-template"),
          menuKey: "operation"
        }
      },
      {
        path: "/management/card-template-view/:id",
        name: "ManagementCardTemplateView",
        component: () => import("../views/management/card-template-view.vue"),
        meta: {
          title: t("v210901.card-template-detail"),
          menuKey: "operation"
        }
      },
      {
        path: "/management/service-list",
        name: "ManagementServices",
        component: () => import("../views/management/service-platform.vue"),
        meta: {
          title: t("v210901.platform-services"),
          menuKey: "operation"
        }
      },
      {
        path: "/management/service-edit/:id",
        name: "ManagementServiceEdit",
        component: () => import("../views/management/service-edits.vue"),
        meta: {
          title: t("setting.editing-service-items"),
          menuKey: "operation"
        }
      },
      {
        path: "/management/service-add",
        name: "ManagementServiceAdd",
        component: () => import("../views/management/service-edits.vue"),
        meta: {
          title: t("setting.add-service-items"),
          menuKey: "operation"
        }
      },
      {
        path: "/management/service-category",
        name: "serviceCategory",
        component: () => import("../views/management/service-category.vue"),
        meta: {
          title: '服务分类',
          menuKey: "operation"
        }
      },

      {
        path: "/management/service-category-add",
        name: "serviceCategoryAdd",
        component: () => import("../views/management/service-category-edit.vue"),
        meta: {
          title: '服务分类新增',
          menuKey: "operation"
        }
      },
      {
        path: "/management/service-category-edit/:categoryCode",
        name: "serviceCategoryEdit",
        component: () => import("../views/management/service-category-edit.vue"),
        meta: {
          title: '服务分类编辑',
          menuKey: "operation"
        }
      },
      {
        path: "/management/announcement-list",
        name: "ManagementAnnouncement",
        component: () => import("../views/management/announcement-list.vue"),
        meta: {
          title: t("v210831.announcement-management"),
          menuKey: "operation"
        }
      },
      {
        path: "/management/announcement-add",
        name: "ManagementAnnouncementAdd",
        component: () => import("../views/management/announcement-add.vue"),
        meta: {
          title: t("v210831.new-announcement"),
          menuKey: "operation"
        }
      },
      {
        path: "/management/announcement-edit/:id",
        name: "ManagementAnnouncementEdit",
        component: () => import("../views/management/announcement-add.vue"),
        meta: {
          title: t("v210831.edit-announcement"),
          navIdx: 2
        }
      },
      {
        path: "/finance/collection-order",
        name: "collectionOrder",
        component: () => import("../views/finance/collection-order.vue"),
        meta: {
          title: "收款单",
          menuKey: "finance-receipt"
        }
      },
      {
        path: "/finance/collection-order-view/:id",
        name: "collectionOrderView",
        component: () => import("../views/finance/collection-order-view.vue"),
        meta: {
          title: "查看收款单",
          menuKey: "finance"
        }
      },
      {
        path: "/finance/withdrawal",
        name: "withdrawalOrder",
        component: () => import("../views/finance/withdrawal-order.vue"),
        meta: {
          title: "提现单",
          menuKey: "finance"
        }
      },
      {
        path: "/management/banner-list",
        name: "ManagementBannerList",
        component: () => import("../views/management/banner-list.vue"),
        meta: {
          title: t("v210831.banner-management"),
          menuKey: "operation"
        }
      },
      {
        path: "/management/banner-add",
        name: "ManagementBannerAdd",
        component: () => import("../views/management/banner-add.vue"),
        meta: {
          title: t("v210831.new-banner"),
          menuKey: "operation"
        }
      },
      {
        path: "/management/banner-edit/:id",
        name: "ManagementBannerEdit",
        component: () => import("../views/management/banner-add.vue"),
        meta: {
          title: t("v210831.edit-banner"),
          navIdx: 2
        }
      },
      {
        path: "/monitor/index",
        name: "monitor",
        component: () => import("../views/monitor/index.vue"),
        meta: {
          title: t("v210831.activity-monitoring"),
          menuKey: "monitor"
        }
      },
      {
        path: "/service/index",
        name: "serviceIndex",
        component: () => import("../views/service/index.vue"),
        meta: {
          title: t("test.service-management"),
          menuKey: "service"
        }
      },
      {
        path: "/service/view/:id",
        name: "serviceView",
        component: () => import("../views/service/service-view.vue"),
        meta: {
          title: t("service.check-service"),
          menuKey: "service"
        }
      },
      {
        path: "/clue/index",
        name: "clueIndex",
        component: () => import("../views/clue/index.vue"),
        meta: {
          title: "服务线索管理",
          menuKey: "clue"
        }
      },
      {
        path: "/customer/index",
        name: "customerIndex",
        component: () => import("../views/customer/index.vue"),
        meta: {
          title: "客户",
          menuKey: "customer"
        }
      },
      {
        path: "/customer/customer-view/:id",
        name: "customerView",
        component: () => import("../views/customer/customer-view.vue"),
        meta: {
          title: "客户详情",
          menuKey: "customer"
        }
      },
      {
        path: "/customer/customer-edit/:id",
        name: "customerEdit",
        component: () => import("../views/customer/customer-edit.vue"),
        meta: {
          title: "客户编辑",
          menuKey: "customer"
        }
      },
      {
        path: "customer/customer-add",
        name: "customerAdd",
        component: () => import("../views/customer/customer-edit.vue"),
        meta: {
          title: t("v210901.Adding-Customer-Information"),
          menuKey: "customer"
        }
      },
      {
        path: "/consult/consult-list",
        name: "consultList",
        component: () => import("../views/consult/consult-list.vue"),
        meta: {
          title: t("v210831.information-management"),
          menuKey: "operation"
        }
      },
      {
        path: "/consult/consult-view/:id",
        name: "consultView",
        component: () => import("../views/consult/consult-view.vue"),
        meta: {
          title: t("v210901.information-detail"),
          menuKey: "operation"
        }
      },
      {
        path: "/consult/consult-add",
        name: "consultAdd",
        component: () => import("../views/consult/consult-edit.vue"),
        meta: {
          title: t("v210831.new-information"),
          menuKey: "operation"
        }
      },
      {
        path: "/consult/consult-edit/:id",
        name: "consultEdit",
        component: () => import("../views/consult/consult-edit.vue"),
        meta: {
          title: t("v210831.edit-information"),
          menuKey: "operation"
        }
      },
      {
        path: "/consult/consult-category-list",
        name: "consultCategoryList",
        component: () => import("../views/consult/consult-category-list.vue"),
        meta: {
          title: t("v210831.information-classification-management"),
          menuKey: "operation"
        }
      },
      {
        path: "setting/role",
        name: "role",
        component: () => import("../views/setting/permission.vue"),
        meta: {
          title: t("v210901.role-permission-information"),
          menuKey: "setting"
        }
      },
      {
        path: "setting/permission-add",
        name: "permissionAdd",
        component: () => import("../views/setting/permission-edit.vue"),
        meta: {
          title: t("v210901.add-role-permission-information"),
          menuKey: "setting"
        }
      },
      {
        path: "setting/role-edit/:id",
        name: "permissionEdit",
        component: () => import("../views/setting/permission-edit.vue"),
        meta: {
          title: t("v210901.edit-role-permission-information"),
          menuKey: "setting"
        }
      },
      {
        path: "setting/role-view/:id",
        name: "permissionView",
        component: () => import("../views/setting/permission-edit.vue"),
        meta: {
          title: t("v210901.check-role-permission-information"),
          menuKey: "setting"
        }
      },
      {
        path: "setting/user",
        name: "staff",
        component: () => import("../views/setting/staff.vue"),
        meta: {
          title: t("v210831.user-information"),
          menuKey: "setting"
        }
      },
      {
        path: "setting/user-add",
        name: "staffAdd",
        component: () => import("../views/setting/staff-edit.vue"),
        meta: {
          title: t("v210901.add-user"),
          menuKey: "setting"
        }
      },
      {
        path: "setting/user-edit/:id",
        name: "staffEdit",
        component: () => import("../views/setting/staff-edit.vue"),
        meta: {
          title: t("setting.edit-employee-information"),
          menuKey: "setting"
        }
      },
      {
        path: "setting/store-type",
        name: "SettingStoreType",
        component: () => import("../views/setting/store-type.vue"),
        meta: {
          title: "门店类型",
          menuKey: "setting"
        }
      },
      {
        path: "setting/dictionary",
        name: "dictionary",
        component: () => import("../views/setting/dictionary.vue"),
        meta: {
          title: "数据字典",
          menuKey: "setting"
        }
      },

      {
        path: "/learning/exam",
        name: "learningExam",
        component: () => import("../views/learning/exam-list.vue"),
        meta: {
          title: t("v210831.examination-certification"),
          menuKey: "learning"
        }
      },
      {
        path: "/learning/exam-view/:id",
        component: () => import("../views/learning/exam-view.vue"),
        meta: {
          title: t("v210901.paper-detail"),
          menuKey: "learning"
        }
      },

      {
        path: "/learning/exam/score",
        name: "learningExamScore",
        component: () => import("../views/learning/exam-score-list.vue"),
        meta: {
          title: t("v210831.marking-and-scoring"),
          menuKey: "learning"
        }
      },
      {
        path: "/learning/exam/scoring/:answerCode",
        name: "scoring",
        component: () => import("../views/learning/exam-scoring.vue"),
        meta: {
          title: t("v210901.reading-scores"),
          menuKey: "learning"
        }
      },
      {
        path: "/learning/exam/scoro-view/:answerCode",
        name: "scoreView",
        component: () => import("../views/learning/exam-scoring.vue"),
        meta: {
          title: t("v210901.check-scores"),
          menuKey: "learning"
        }
      },
      {
        path: "/learning/exam/level-list",
        name: "examLevelList",
        component: () => import("../views/learning/exam-level-list.vue"),
        meta: {
          title: t("v210831.certification-level-not-symbol"),
          menuKey: "learning"
        }
      },
      {
        path: "/learning/exam/level-edit",
        name: "examLevelEdit",
        component: () => import("../views/learning/exam-level-edit.vue"),
        meta: {
          title: t("v210831.new-level"),
          menuKey: "learning"
        }
      },
      {
        path: "learning/learning-list",
        name: "learningList",
        component: () => import("../views/learning/learning-list.vue"),
        meta: {
          title: t("v210831.learning-content-management"),
          menuKey: "learning"
        }
      },
      {
        path: "learning/learning-add",
        name: "learningAdd",
        component: () => import("../views/learning/learning-edit.vue"),
        meta: {
          title: t("v210831.new-learning-content"),
          menuKey: "learning"
        }
      },
      {
        path: "learning/learning-edit/:id",
        name: "learningEdit",
        component: () => import("../views/learning/learning-edit.vue"),
        meta: {
          title: t("v210831.edit-learning-content"),
          menuKey: "learning"
        }
      },
      {
        path: "learning/learning-view/:id",
        name: "learningView",
        component: () => import("../views/learning/learning-view.vue"),
        meta: {
          title: t("v210901.learning-content-detail"),
          menuKey: "learning"
        }
      },
      {
        path: "/learning/exam-add",
        name: "learningExamAdd",
        component: () => import("../views/learning/exam-edit.vue"),
        meta: {
          title: t("v210831.new-exam"),
          menuKey: "learning"
        }
      },
      {
        path: "/learning/exam-edit/:id",
        name: "learningExamEdit",
        component: () => import("../views/learning/exam-edit.vue"),
        meta: {
          title: t("v210831.editing-test"),
          menuKey: "learning"
        }
      },
      {
        path: "learning/learning-category-list",
        name: "learningCategoryList",
        component: () => import("../views/learning/learning-category-list.vue"),
        meta: {
          title: t("v210831.learning-classification-management"),
          menuKey: "learning"
        }
      },
      {
        path: "learning/interaction-list",
        name: "learningInteractionList",
        component: () => import("../views/learning/interaction-list.vue"),
        meta: {
          title: t("v210801.interactive-qa"),
          menuKey: "learning"
        }
      },
      {
        path: "learning/interaction-view/:id",
        name: "learningInteractionView",
        component: () => import("../views/learning/interaction-view.vue"),
        meta: {
          title: t("v210831.post-body"),
          menuKey: "learning"
        }
      },
      {
        path: "learning/interaction-mine",
        name: "learningInteractionMine",
        component: () => import("../views/learning/interaction-mine.vue"),
        meta: {
          title: t("v210801.my-interaction"),
          menuKey: "learning"
        }
      },
      {
        path: "learning/interaction-category-list",
        name: "learningInteractionCategoryList",
        component: () => import("../views/learning/interaction-category-list.vue"),
        meta: {
          title: t("v210801.interactive-type-management"),
          menuKey: "learning"
        }
      },
      {
        path: "package/list",
        name: "packageInfoList",
        component: () => import("../views/package/index.vue"),
        meta: {
          title: "组合套餐",
          menuKey: "package"
        }
      },
      {
        path: "package/view/:packageCode",
        name: "packageInfoList",
        component: () => import("../views/package/view.vue"),
        meta: {
          title: "组合套餐查看",
          menuKey: "package"
        }
      },
      {
        path: "package/add",
        name: "packageInfoList",
        component: () => import("../views/package/edit.vue"),
        meta: {
          title: "组合套餐编辑",
          menuKey: "package"
        }
      },
      {
        path: "package/edit/:packageCode",
        name: "packageInfoList",
        component: () => import("../views/package/edit.vue"),
        meta: {
          title: "组合套餐编辑",
          menuKey: "package"
        }
      },
      {
        path: "maintenance/detecationItem/list",
        name: "detecationItemList",
        component: () => import("../views/maintenance/detecation-items.vue"),
        meta: {
          title: "养车项目",
          menuKey: "maintenance"
        }
      },
      {
        path: "maintenance/detecationItem/view/:projectCode",
        name: "detecationItemView",
        component: () => import("../views/maintenance/detecation-items-view.vue"),
        meta: {
          title: "养车项目查看",
          menuKey: "maintenanceView"
        }
      },
      {
        path: "maintenance/detecationItem/edit/:projectCode",
        name: "detecationItemEdit",
        component: () => import("../views/maintenance/detecation-items-edit.vue"),
        meta: {
          title: "养车项目编辑",
          menuKey: "maintenanceEdit"
        }
      },
      {
        path: "maintenance/detecationItem/add",
        name: "detecationItemAdd",
        component: () => import("../views/maintenance/detecation-items-edit.vue"),
        meta: {
          title: "养车项目新建",
          menuKey: "maintenanceAdd"
        }
      },
      {
        path: "maintenance/detecationTemplate/list",
        name: "detecationTemplateList",
        component: () => import("../views/maintenance/detecation-template.vue"),
        meta: {
          title: "养车日历模板",
          menuKey: "maintenance"
        }
      },{
        path: "/maintenance/detecationTemplate/add",
        name: "detecationTemplateAdd",
        component: () => import("../views/maintenance/detecation-template-edit.vue"),
        meta: {
          title: "养车日历模板创建",
          menuKey: "maintenance"
        }
      },
      {
        path: "/maintenance/detecationTemplate/edit/:templateId",
        name: "detecationTemplateEdit",
        component: () => import("../views/maintenance/detecation-template-edit.vue"),
        meta: {
          title: "养车日历模板修改",
          menuKey: "maintenance"
        }
      },
      {
        path: "/maintenance/detecationTemplate/view/:templateId",
        name: "detecationTemplateEdit",
        component: () => import("../views/maintenance/detecation-template-view.vue"),
        meta: {
          title: "养车日历模板查看",
          menuKey: "maintenance"
        }
      },{
        path: "/maintenance/maintainProject/item",
        name: "maintainProjectItemAdd",
        component: () => import("../views/maintenance/maintain-project-items.vue"),
        meta: {
          title: "养车日历模板创建",
          menuKey: "maintenance"
        }
      },
      {
        path: "maintenance/maintainProject/item/add",
        name: "maintainProjectItemAdd",
        component: () => import("../views/maintenance/maintain-project-edit.vue"),
        meta: {
          title: "养车项目新建",
          menuKey: "maintenanceAdd"
        }
      },
      {
        path: "/newCarUser/index",
        name: "newCarUserIndex",
        component: () => import("../views/newCarUser/index.vue"),
        meta: {
          title: "新车售卖客户",
          menuKey: "newCarUser"
        }
      },
      {
        path: "/bxClue/index",
        name: "bxClueIndex",
        component: () => import("../views/bxClue/index.vue"),
        meta: {
          title: "保险线索",
          menuKey: "newCarUser"
        }
      },

      {
        path: "/bxClueCustomer/index",
        name: "bxClueCustomerIndex",
        component: () => import("../views/bxClueCustomer/index.vue"),
        meta: {
          title: "保险线索客户",
          menuKey: "newCarUser"
        }
      },
      {
        path: "/quality/assurance/index",
        name: "qualityAssuranceIndex",
        component: () => import("../views/qualityAssurance/index.vue"),
        meta: {
          title: "质保单模版",
          menuKey: "newCarUser"
        }
      },
      {
        path: "/quality/assurance/add",
        name: "qualityAssuranceAdd",
        component: () => import("../views/qualityAssurance/edit.vue"),
        meta: {
          title: "质保单模版新增",
          menuKey: "setting"
        }
      },
      {
        path: "/quality/assurance/edit/:id",
        name: "qualityAssuranceEdit",
        component: () => import("../views/qualityAssurance/edit.vue"),
        meta: {
          title: "质保单模版编辑",
          menuKey: "setting"
        }
      },
      {
        path: "/plusCard/template/index",
        name: "plusCardTemplateIndex",
        component: () => import("../views/plusCard/plus-template-list.vue"),
        meta: {
          title: "plus卡模板列表",
          menuKey: "plusCardTemplateIndex"
        }
      },{
        path: "/plusCard/template/edit/:plusTemplateCode",
        name: "plusCardTemplateEdit",
        component: () => import("../views/plusCard/plus-template-edit.vue"),
        meta: {
          title: "plus卡模板编辑",
          menuKey: "plusCardTemplateEdit"
        }
      },{
        path: "/plusCard/template/add",
        name: "plusCardTemplateAdd",
        component: () => import("../views/plusCard/plus-template-edit.vue"),
        meta: {
          title: "plus卡模板添加",
          menuKey: "plusCardTemplateAdd"
        }
      },
      {
        path: "/plusCard/template/view/:plusTemplateCode",
        name: "plusCardTemplateView",
        component: () => import("../views/plusCard/plus-template-view.vue"),
        meta: {
          title: "plus卡模板查看",
          menuKey: "plusCardTemplateView"
        }
      },
      {
        path: "/plusCard/customer/list",
        name: "plusCardCustomerList",
        component: () => import("../views/plusCard/plus-customer-list.vue"),
        meta: {
          title: "plus卡购买列表",
          menuKey: "plusCardCustomerList"
        }
      },
      {
        path: "/userTask/conf/index",
        name: "userTaskConfIndex",
        component: () => import("../views/userTask/userTaskConf-list.vue"),
        meta: {
          title: "用户任务配置",
          menuKey: "userTaskConf"
        }
      },
      {
        path: "/userTask/conf/edit/:taskNo",
        name: "userTaskConfEdit",
        component: () => import("../views/userTask/userTaskConf-edit.vue"),
        meta: {
          title: "用户任务配置编辑",
          menuKey: "userTaskConfEdit"
        }
      },
      {
        path: "/userTask/conf/add",
        name: "userTaskConfAdd",
        component: () => import("../views/userTask/userTaskConf-edit.vue"),
        meta: {
          title: "用户任务配置添加",
          menuKey: "userTaskConfAdd"
        }
      },
      {
        path: "/userTask/conf/view/:taskNo",
        name: "userTaskConfIndex",
        component: () => import("../views/userTask/userTaskConf-view.vue"),
        meta: {
          title: "用户任务配置查看",
          menuKey: "userTaskConfView"
        }
      },
      {
        path: "/combinedPackage/list",
        name: "combinedPackageList",
        component: () => import("../views/management/combinedPackage/list.vue"),
        meta: {
          title: "组合营销",
          menuKey: "combinedPackage"
        }
      },
      {
        path: "/combinedPackage/edit/:id",
        name: "combinedPackageEdit",
        component: () => import("../views/management/combinedPackage/add.vue"),
        meta: {
          title: "修改套餐",
          menuKey: "combinedPackageEdit"
        }
      },
      {
        path: "/combinedPackage/add",
        name: "combinedPackageAdd",
        component: () => import("../views/management/combinedPackage/add.vue"),
        meta: {
          title: "新建套餐",
          menuKey: "combinedPackageAdd"
        }
      },
      {
        path: "/combinedPackage/dashboard/:id",
        name: "combinedPackageDashboard",
        component: () => import("../views/management/combinedPackage/dashboard.vue"),
        meta: {
          title: "数据面板",
          menuKey: "combinedPackageDashboard"
        }
      },
      {
        path: "/flowActivity/list",
        name: "flowActivityList",
        component: () => import("../views/management/flowActivity/list.vue"),
        meta: {
          title: "引流活动",
          menuKey: "flowActivity"
        }
      },
      {
        path: "/flowActivity/add",
        name: "flowActivityAdd",
        component: () => import("../views/management/flowActivity/add.vue"),
        meta: {
          title: "新建引流活动",
          menuKey: "flowActivityAdd"
        }
      },
      {
        path: "/flowActivity/data",
        name: "flowActivityData",
        component: () => import("../views/management/flowActivity/data.vue"),
        meta: {
          title: "活动参与明细",
          menuKey: "flowActivityData"
        }
      },
      {
        path: "/crowdManagement/list",
        name: "crowdManagementList",
        component: () => import("../views/management/crowdManagement/list.vue"),
        meta: {
          title: "人群列表",
          menuKey: "crowdManagementList"
        }
      },
      {
        path: "/crowdManagement/add",
        name: "crowdManagementAdd",
        component: () => import("../views/management/crowdManagement/circlingAdd.vue"),
        meta: {
          title: "创建圈选人群",
          menuKey: "crowdManagementAdd"
        }
      },
      {
        path: "/crowdManagement/group",
        name: "crowdManagementGroup",
        component: () => import("../views/management/crowdManagement/circlingAdd.vue"),
        meta: {
          title: "创建组合人群",
          menuKey: "crowdManagementGroup"
        }
      },
      {
        path: "/crowdManagement/crowdChart",
        name: "crowdManagementChart",
        component: () => import("../views/management/crowdManagement/crowdChart.vue"),
        meta: {
          title: "人群洞察",
          menuKey: "crowdManagementChart"
        }
      },
      {
        path: "/crowdManagement/crowdUserResult/:crowdNo",
        name: "crowdManagementAdd",
        component: () => import("../views/management/crowdManagement/crowdUserResult.vue"),
        meta: {
          title: "人群结果",
          menuKey: "crowdUserResult"
        }
      },
      {
        path: "/app/app-version-list",
        name: "appVersionList",
        component: () => import("../views/app/app-version-list.vue"),
        meta: {
          title: "app版本管理",
          menuKey: "operation"
        }
      },
      {
        path: "/app/app-version-add",
        name: "addAppVersion",
        component: () => import("../views/app/app-version-add.vue"),
        meta: {
          title: "新增app版本",
          menuKey: "operation"
        }
      },
      {
        path: "/app/app-version-edit/:id",
        name: "updateAppVersion",
        component: () => import("../views/app/app-version-add.vue"),
        meta: {
          title: "编辑app版本",
          menuKey: "operation"
        }
      },
      {
        path: "/user/member-list/",
        name: "userMemberList",
        component: () => import("../views/user/member-list.vue"),
        meta: {
          title: "经验值管理",
          menuKey: "operation"
        }
      },
      {
        path: "/user/member-level-list/",
        name: "userMemberLevelList",
        component: () => import("../views/user/member-level-list.vue"),
        meta: {
          title: "等级配置",
          menuKey: "operation"
        }
      },
      {
        path: "/user/member-level-edit/:id",
        name: "userMemberLevelEdit",
        component: () => import("../views/user/member-level-edit.vue"),
        meta: {
          title: "编辑等级",
          menuKey: "operation"
        }
      },
      {
        path: "/user/member-level-add/",
        name: "userMemberLevelAdd",
        component: () => import("../views/user/member-level-edit.vue"),
        meta: {
          title: "添加等级",
          menuKey: "operation"
        }
      },
      {
        path: "/setting/menu",
        name: "userMemberLevelAdd",
        component: () => import("../views/setting/menu.vue"),
        meta: {
          title: "菜单管理",
          menuKey: "operation"
        }
      },
      {
        path: "/management/store-edit-new/:storeCode",
        name: "storeEditByStoreCode",
        component: () => import("../views/management/store-edit-new.vue"),
        meta: {
          title: "菜单管理",
          menuKey: "operation"
        }
      },
      {
        path: "/new-car/order",
        name: "newCarOrder",
        component: () => import("../views/newCar/order/index.vue"),
        meta: {
          title: "新车订单",
          menuKey: "operation"
        }
      },
      {
        path: "/management/content/contentModule/list",
        name: "contentModuleList",
        component: () => import("../views/management/content/contentModule/list.vue"),
        meta: {
          title: "内容管理列表",
          menuKey: "contentModuleList"
        }
      },
      {
        path: "/management/content/contentModule/add",
        name: "contentModuleAdd",
        component: () => import("../views/management/content/contentModule/add.vue"),
        meta: {
          title: "新增内容管理",
          menuKey: "contentModuleAdd"
        }
      },
      {
        path: "/management/content/contentModule/edit",
        name: "contentModuleEdit",
        component: () => import("../views/management/content/contentModule/add.vue"),
        meta: {
          title: "编辑内容管理",
          menuKey: "contentModuleEdit"
        }
      },
      {
        path: "/management/content/contentModule/detail",
        name: "contentModuleDetail",
        component: () => import("../views/management/content/contentModule/add.vue"),
        meta: {
          title: "内容管理详情",
          menuKey: "contentModuleDetail"
        }
      },

      {
        path: "/management/content/channelPit/list",
        name: "channelPitList",
        component: () => import("../views/management/content/channelPit/list.vue"),
        meta: {
          title: "渠道&坑位列表",
          menuKey: "channelPitList"
        }
      },
      {
        path: "/management/content/channelPit/add",
        name: "channelPitAdd",
        component: () => import("../views/management/content/channelPit/add.vue"),
        meta: {
          title: "新增子分类",
          menuKey: "channelPitAdd"
        }
      },
      {
        path: "/management/content/channelPit/edit",
        name: "channelPitEdit",
        component: () => import("../views/management/content/channelPit/add.vue"),
        meta: {
          title: "编辑子分类",
          menuKey: "channelPitEdit"
        }
      },
      {
        path: "/management/content/contentDistribution/list",
        name: "contentDistributionList",
        component: () => import("../views/management/content/contentDistribution/list.vue"),
        meta: {
          title: "内容分发列表",
          menuKey: "contentDistributionList"
        }
      },
      {
        path: "/management/content/contentDistribution/add",
        name: "contentDistributionAdd",
        component: () => import("../views/management/content/contentDistribution/add.vue"),
        meta: {
          title: "新建分发",
          menuKey: "contentDistributionAdd"
        }
      },
      {
        path: "/management/content/contentDistribution/edit",
        name: "contentDistributionEdit",
        component: () => import("../views/management/content/contentDistribution/add.vue"),
        meta: {
          title: "新建分发",
          menuKey: "contentDistributionEdit"
        }
      },
      {
        path: "/guidance/index",
        name: "guidanceIndex",
        component: () => import("../views/guidance/index.vue"),
        meta: {
          title: t("v210801.model-matching"),
          menuKey: "guidance"
        }
      },
      {
        path: "/management/groupBuying/groupActivity/add",
        name: "groupActivityAdd",
        component: () => import("../views/management/groupBuying/groupActivity/add.vue"),
        meta: {
          title: "新建拼团",
          menuKey: "groupActivityAdd"
        }
      },
      {
        path: "/management/groupBuying/groupActivity/edit",
        name: "groupActivityEdit",
        component: () => import("../views/management/groupBuying/groupActivity/add.vue"),
        meta: {
          title: "修改拼团",
          menuKey: "groupActivityEdit"
        }
      },
      {
        path: "/management/groupBuying/groupActivity/detail",
        name: "groupActivityDetail",
        component: () => import("../views/management/groupBuying/groupActivity/add.vue"),
        meta: {
          title: "拼团活动详情",
          menuKey: "groupActivityDetail"
        }
      },
      {
        path: "/management/groupBuying/groupActivity/list",
        name: "groupActivityList",
        component: () => import("../views/management/groupBuying/groupActivity/list.vue"),
        meta: {
          title: "拼团活动列表",
          menuKey: "groupActivityList"
        }
      },
      {
        path: "/management/groupBuying/groupOrder/list",
        name: "groupOrderList",
        component: () => import("../views/management/groupBuying/groupOrder/list.vue"),
        meta: {
          title: "拼团记录",
          menuKey: "groupOrderList"
        }
      },
      {
        path: "/management/groupBuying/groupOrder/detail",
        name: "groupOrderDetail",
        component: () => import("../views/management/groupBuying/groupOrder/detail.vue"),
        meta: {
          title: "拼团记录",
          menuKey: "groupOrderDetail"
        }
      },
      {
        path: "/insurance/insuranceInstitute/list",
        name: "insuranceInstituteList",
        component: () => import("../views/insurance/insuranceInstitute/list.vue"),
        meta: {
          title: "保险机构",
          menuKey: "insuranceInstituteList"
        }
      },
      {
        path: "/insurance/insurancePackage/list",
        name: "insurancePackageList",
        component: () => import("../views/insurance/insurancePackage/list.vue"),
        meta: {
          title: "保险套餐",
          menuKey: "insurancePackageList"
        }
      },
      {
        path: "/insurance/insurancePackage/add",
        name: "insurancePackageAdd",
        component: () => import("../views/insurance/insurancePackage/add.vue"),
        meta: {
          title: "新增套餐",
          menuKey: "insurancePackageAdd"
        }
      },
      {
        path: "/insurance/insurancePackage/edit",
        name: "insurancePackageEdit",
        component: () => import("../views/insurance/insurancePackage/add.vue"),
        meta: {
          title: "编辑套餐",
          menuKey: "insurancePackageEdit"
        }
      },
      {
        path: "/insurance/insurancePackage/data",
        name: "insurancePackageOrderData",
        component: () => import("../views/insurance/insurancePackage/orderData.vue"),
        meta: {
          title: "套餐数据",
          menuKey: "insurancePackageOrderData"
        }
      },
      {
        path: "/insurance/insuranceTemplate/list",
        name: "insuranceTemplateList",
        component: () => import("../views/insurance/insuranceTemplate/list.vue"),
        meta: {
          title: "保险模版",
          menuKey: "insuranceTemplateList"
        }
      },
      {
        path: "/insurance/insuranceQuotation/list",
        name: "insuranceQuotationList",
        component: () => import("../views/insurance/insuranceQuotation/list.vue"),
        meta: {
          title: "报价单",
          menuKey: "insuranceQuotationList"
        }
      },

      {
        path: "/insurance/insuranceQuotation/view/:id",
        name: "insuranceQuotationView",
        component: () => import("../views/insurance/insuranceQuotation/view.vue"),
        meta: {
          title: "报价单详情",
          menuKey: "insuranceQuotationView"
        }
      },
      {
        path: "/insurance/electronicInsurance/list",
        name: "electronicInsuranceList",
        component: () => import("../views/insurance/electronicInsurance/list.vue"),
        meta: {
          title: "电子保单",
          menuKey: "electronicInsuranceList"
        }
      },
      {
        path: "/insurance/insuranceAgencyEvent/list",
        name: "insuranceAgencyEventList",
        component: () => import("../views/insurance/insuranceAgencyEvent/list.vue"),
        meta: {
          title: "代理事件",
          menuKey: "insuranceAgencyEventList"
        }
      },
      {
        path: "/insurance/insuranceAgencyEvent/add",
        name: "insuranceAgencyEventAdd",
        component: () => import("../views/insurance/insuranceAgencyEvent/edit.vue"),
        meta: {
          title: "代理事件新建",
          menuKey: "insuranceAgencyEventAdd"
        }
      },
      {
        path: "/insurance/insuranceAgencyEvent/edit",
        name: "insuranceAgencyEventEdit",
        component: () => import("../views/insurance/insuranceAgencyEvent/edit.vue"),
        meta: {
          title: "代理事件编辑",
          menuKey: "insuranceAgencyEventEdit"
        }
      },
      {
        path: "/insurance/insuranceAgencyEvent/show",
        name: "insuranceAgencyEventShow",
        component: () => import("../views/insurance/insuranceAgencyEvent/edit.vue"),
        meta: {
          title: "代理事件查看",
          menuKey: "insuranceAgencyEventShow"
        }
      },
      {
        path: "/insurance/insuranceTypes/list",
        name: "insuranceTypesList",
        component: () => import("../views/insurance/insuranceTypes/list.vue"),
        meta: {
          title: "险种管理",
          menuKey: "insuranceTypesList"
        }
      },
      {
        path: "/insurance/insuranceAgency/list",
        name: "insuranceAgencyList",
        component: () => import("../views/insurance/insuranceAgency/list.vue"),
        meta: {
          title: "保险代理任务",
          menuKey: "insuranceAgencyList"
        }
      },
      {
        path: "/insurance/insuranceAgency/add",
        name: "insuranceAgencyAdd",
        component: () => import("../views/insurance/insuranceAgency/add.vue"),
        meta: {
          title: "新建奖励任务",
          menuKey: "insuranceAgencyAdd"
        }
      },
      {
        path: "/insurance/insuranceAgency/edit",
        name: "insuranceAgencyEdit",
        component: () => import("../views/insurance/insuranceAgency/add.vue"),
        meta: {
          title: "编辑奖励任务",
          menuKey: "insuranceAgencyAdd"
        }
      },
      {
        path: "/insurance/insuranceAgency/detail",
        name: "insuranceAgencyShow",
        component: () => import("../views/insurance/insuranceAgency/add.vue"),
        meta: {
          title: "新建奖励任务",
          menuKey: "insuranceAgencyAdd"
        }
      },
      {
        path: "/maintainTemplate/add",
        name: "maintainTemplateAdd",
        component: () => import("../views/management/carMaintain/maintainTemplate/add.vue"),
        meta: {
          title: "保养模版",
          menuKey: "maintainTemplateAdd"
        }
      },
      {
        path: "/maintainBrand/list",
        name: "maintainBrandList",
        component: () => import("../views/management/carMaintain/maintainBrand/list.vue"),
        meta: {
          title: "保养品牌",
          menuKey: "maintainBrandList"
        }
      },
      {
        path: "/wxcpSetTagTask/list",
        name: "wxcpSetTagTaskList",
        component: () => import("../views/wxCp/wxcpSetTagTaskList.vue"),
        meta: {
          title: "企微客户打标",
          menuKey: "wxcpSetTagTaskList"
        }
      },
      {
        path: "/wxcpSetTagTask/add",
        name: "wxcpSetTagTaskAdd",
        component: () => import("../views/wxCp/wxcpSetTagTask-edit.vue"),
        meta: {
          title: "企微客户打标任务添加",
          menuKey: "wxcpSetTagTaskAdd"
        }
      },
      ,
      {
        path: "/wxcpSetTagTask/view",
        name: "wxcpSetTagTaskView",
        component: () => import("../views/wxCp/wxcpSetTagTask-view.vue"),
        meta: {
          title: "企微客户打标任务详情",
          menuKey: "wxcpSetTagTaskView"
        }
      },
      ...commonRouters
    ]
  },

  { path: "*", redirect: "/404" }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  let menuList = localStorage.getItem("se_menus")?JSON.parse(localStorage.getItem("se_menus")) : [];
  if (menuList.length < 1 && to.name != "login") {
    next("/login");
  } else {
    next();
  }
});

router.afterEach(to => {
  document.title = to.meta.title;
});
export default router;
