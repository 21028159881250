import { getTestPort } from "@/utils/common";
const VUE_APP_URL_API = getTestPort();
const store = {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async getQualityAssuranceTemplateList({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/op/quality/assurance/template/list", params);
      return data;
    },

    async editQualityAssuranceTemplate({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/op/quality/assurance/template/edit", params);
      return data;
    },

    async getQualityAssuranceTemplateDetail({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + `/op/quality/assurance/template/${params}` , params);
      return data;
    },

    async stateChange({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/op/quality/assurance/template/change" , params);
      return data;
    },

    async assign({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/op/quality/assurance/template/assign" , params);
      return data;
    },

    async getOptions({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/op/quality/assurance/template/options" , params);
      return data;
    },

    async getServiceStoreList({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/op/quality/assurance/service/store/list" , params);
      return data;
    },

    async matchServicesAndStores({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/op/quality/assurance/match/service/store/list" , params);
      return data;
    },

    async cancelAssign({ rootState }, params = {}) {
      const data = await rootState.http(VUE_APP_URL_API + "/op/quality/assurance/template/cancel/assign" , params);
      return data;
    },
  }
};

export default store;
