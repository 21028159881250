
import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { emptyToLine, splitThousands } from "@/utils/common";
import StepList from "@/components/scope/step-list.vue";
import dayjs from "dayjs";
import { priceRulesEl } from "@/utils/verify";
import ReturnCard from "./components/return-card.vue";
import t from "@common/src/i18n/t";
import Template from "@/views/dashboard/template.vue";

const marketing = namespace("marketing");
const setting = namespace("setting");

@Component({
  components: {
    Template,
    StepList,
    ReturnCard
  }
})
export default class PurchaseView extends Vue {
  @marketing.Action queryCardDetail;
  @setting.Action getPatternPaymentList;
  @marketing.Action updateCardStatusRefund;
  @marketing.Action cardActivation;
  @marketing.Action manualVerification;
  dayjs = dayjs;
  splitThousands = splitThousands;
  emptyToLine = emptyToLine;
  get breadData() {
    return [{ name: t("marketing.card-manage"), path: "/marketing/card" }, { name: t("v210901.card-details") }];
  }
  paymentData: any = {};
  payWayList: any = [];

  manualVerificationForm:any ={
    customerCode: "",
    orderCode: "",
    cardConsumeAmount: "",
    cardList:[]
  }

  cardOrderParam:any = {
    cardCode: "",
    consumeCount: null,
    consumeAmount: "",
    serviceList: [] ,
    partsList: []
  }

  manualVerificationDialogVisible: boolean = false;

  created() {
    this.init();
  }
  get orderId() {
    return this.$route.params.id;
  }
  detailData: any = {
    operateList: []
  };
  refundLoading: boolean = false;
  groupIndex: number = 1;
  init() {
    this.queryCardDetail({
      cardCode: this.orderId
    }).then(data => {
      data = data.data || {};
      if (data.cardType === 1) {
        data.serviceList &&
          data.serviceList.forEach(serviceItem => {
            if (serviceItem.partsList && serviceItem.partsList.length > 0) {
              serviceItem.isGroup = true;
              serviceItem.groupServiceIndex = this.groupIndex;
              serviceItem.partsList.forEach(partsItem => {
                data.partsList.push(
                  Object.assign(partsItem, {
                    isGroup: true,
                    groupServiceIndex: this.groupIndex
                  })
                );
              });
              this.groupIndex++;
            }
          });
      }
      this.detailData = data;
    });
    this.getPatternPaymentList({
      status: "1"
    }).then(data => {
      this.payWayList = this.$safeValue(data, "data", []);
    });
  }
  showRefundModal: boolean = false;
  refundForm: any = {
    cardCode: "",
    returnAmount: "0",
    returnMethod: "",
    returnRemark: ""
  };
  refundFormRule: any = {
    returnAmount: [
      { required: true, message: t("v210906.please-enter-the-actual-refund-amount"), trigger: "blur" },
      { validator: this.checkPrice, trigger: "blur" }
    ],
    returnRemark: { required: true, message: t("v210831.please-enter-refund"), trigger: "blur" },
    returnMethod: { required: true, message: t("finance.select-mode-payment"), trigger: "change" }
  };
  checkPrice(rule, value, callback) {
    priceRulesEl(value, callback);
  }
  recommendReturnAmount: string = "";
  handleShowRefund() {
    const refs = this.$refs.returnCard as any;
    refs.showRefundModalFn();
    this.showRefundModal = true;
    if (Number(this.detailData.cardAmount) > 0 && Number(this.detailData.carryingAmount) > 0) {
      this.refundForm.returnAmount = this.recommendReturnAmount = (
        this.detailData.carryingBalance *
        (this.detailData.cardAmount / this.detailData.carryingAmount) *
        0.8
      ).toFixed(2);
    } else if (Number(this.detailData.carryingAmount) === 0) {
      // 账面金额为0
      this.refundForm.returnAmount = this.recommendReturnAmount = "";
    } else {
      this.refundForm.returnAmount = this.recommendReturnAmount = "0.00";
    }
    this.refundForm.cardCode = this.orderId;
  }
  handleActivation() {
    const { cardCode } = this.detailData;
    this.$confirm("确定要激活吗", t("v210831.tips"), {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning"
    })
      .then(() => {
        this.cardActivation({ cardCode }).then(res => {
          this.$message.success("激活成功");
          this.init();
        });
      })
      .catch(() => {});
  }
  handleconfirmRefund() {
    this.init();
    this.showRefundModal = false;
  }

  manualVerificationDialog(row,type){
    this.cardOrderParam.cardCode = this.detailData.cardCode;
    this.manualVerificationForm.customerCode = this.detailData.customerCode;
    this.manualVerificationForm.cardList = [];
    switch (type){
      case "service":
        this.cardOrderParam.serviceList = [];
        row.consumeCount = 1;
        this.cardOrderParam.serviceList.push(row)
        break;
      case "parts":
        this.cardOrderParam.partsList = [];
        this.cardOrderParam.partsList.push(row)
        break;
      default:
        this.$message.error("操作失败")
        return;
    }
    this.manualVerificationDialogVisible = true;
  }

  doManualVerification(){
    this.manualVerificationForm.cardList.push(this.cardOrderParam)
    this.manualVerification(this.manualVerificationForm).then(res=>{
      if (res.code === '0'){
        this.$message.success("核销成功");
        this.manualVerificationDialogVisible = false;
        this.$router.go(0);
      }else{
        this.$message.error(res.data.msg);
      }
    })
  }
}
